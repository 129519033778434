<template>
  <div>
    <Breadcrumb :breadcrumbdata="breadcrumbdata"></Breadcrumb>
    <div class="content-wrap">
      <!-- 用户信息 -->
      <div class="user-info">
        <div class="user-info-left">
          <div class="img">
            <i class="icon icon-qiye"></i>
          </div>
          <div>
            <div class="txt1">{{epName}}</div>
            <div class="txt2">{{email}}</div>
          </div>
        </div>
        <div class="user-info-right">
          使用开放平台服务次数：<span class="txt1">{{useTotal}}</span>
        </div>
      </div>
      <el-divider class="divider"></el-divider>
      <!-- 余额/服务占比 -->
      <div class="use-money">
        <div class="use-money-left">
          <div class="list mb20">
            <div class="list-card list-card1">
              <div class="img">
                <i class="icon icon-qitatubiao-1"></i>
              </div>
              <div class="con">
                <div class="txt1" >账户余额</div>
                <div class="txt2 money-num" :title='accountBalance'>{{accountBalance}}元</div>
              </div>
              <div class="txt3" @click="goWallet('isShowChong')">充值</div>
            </div>
            <div class="list-card-gap"></div>
            <div class="list-card list-card2">
              <div class="img"><i class="icon icon-qitatubiao-1"></i></div>
              <div class="con">
                <div class="txt1">本月消费</div>
                <div class="txt2 money-num" :title='mounthConsumption'>{{mounthConsumption}}元</div>
              </div>
              <div class="txt3" style="visibility: hidden">充值</div>
            </div>
          </div>
          <div class="list">
            <div class="list-card list-card3">
              <div class="img">
                <i class="icon icon-taocan-2"></i>
              </div>
              <div class="con">
                <div class="txt1">存证API套餐余量</div>
                <div class="txt2 money-num">{{usable}}次</div>
              </div>
              <div class="txt3" @click="goWallet('isShowBuy')">购买</div>
            </div>
            <div class="list-card-gap"></div>
            <!-- <div class="list-card mr20 list-card4">
              <div class="img"><i class="icon icon-taocan-2"></i></div>
              <div class="con">
                <div class="txt1">取证API套餐余量</div>
                <div class="txt2 money-num">{{getusable}}次</div>
              </div>
              <div class="txt3"></div>
            </div> -->
          </div>
        </div>
        <div class="use-money-right">
          <div class="txt1">
            使用服务类别占比
          </div>
          <div :style="{ width: '100%' }">
            <div
              id="categoryChart"
              ref="categoryChart"
              :style="{ width: '100%', height: '240px' }"
            ></div>
          </div>
        </div>
      </div>
      <el-divider class="divider"></el-divider>
      <!-- 服务使用情况 -->
      <div class="use-service">
        <div class="tt1">
          服务使用情况
        </div>
        <div class="use-service-category">
          <div class="left">
            <span class="tt2">业务:</span>
            <div class="tabs">
              <span
                v-for="(item) in busiDetailTypeOptions"
                :key="item.lebel"
                @click="
                  changeBusinessTrendsActive(item.value, 'businessTrendsActive')
                "
                :class="[item.value == businessTrendsActive ? 'active' : '']"
                class="tt3"
                >{{ item.lebel }}</span>
            </div>
          </div>
          <div class="right">
               <template v-for="(item, index) in listTypeOptions">
                  <span
                   :key="item.lebel"
                    @click="changeTimeBusinessTrendsActive(item.value, 'timeBusinessTrendsActive')"
                    :class="[
                      item.value == timeBusinessTrendsActive ? 'active' : '',
                    ]"
                    class="item"
                    >{{ item.lebel }}</span>
                  <el-divider v-if="index != (listTypeOptions.length-1)" :key="item.lebel" direction="vertical"></el-divider>
              </template>
          </div>
        </div>
        <div :style="{ width: '100%' }">
          <div
            id="serviceChart"
            ref="serviceChart"
            style="width: 100%;height:250px"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getApply,checkIdCard } from '@/api/user'
import { getBusiDetailType } from "@/config/index.js"
import { getStatisticTotal ,getWalletSpend,getStatisticTimeList ,getWalletBalance,getCurrentPackageTotal} from '@/api/overview'
import Breadcrumb from "@/components/common/Breadcrumb"
export default {
  name: "overview",
  components: {
    Breadcrumb
  },
  data() {
    return {
      apply: {}, //企业信息
      exist: 0,
      serviceCategory:[], // 使用服务类别占比名字
      serviceCategoryData:[
              // { value: 1335, name: "存证API" },
              // { value: 310, name: "区块链合同" },
              // { value: 234, name: "在线公证" },
              // { value: 135, name: "版权监测" },
              // { value: 548, name: "录屏取证" }
      ], // 使用服务类别占比数据
      screenWidth: document.body.clientWidth,
      usable:0, // 存证套餐余量
      getusable:0, // 取证套餐余量
      accountBalance:'0', // 账户余额
      mounthConsumption: '0',// 本月消费
      brokenStatisticTime: [], // 统计单个节点时间(折线) broken
      brokenStatisticNum: [], // 统计出来的数量量(折线)
      businessTrendsActive: 2,// 类型选择
      timeBusinessTrendsActive: 3, // 时间选择
      busiDetailTypeOptions: [
        // {
        //   value: '1',
        //   lebel: '网页取证',
        // },
        {
          value: '2',
          lebel: '存证API',
        },
        // {
        //   value: '3',
        //   lebel: '区块链合同',
        // },
      ],
      epName:'',// 企业名称
      email:'',// 企业邮箱
      useTotal:'0',//使用开放平台服务次数
      breadcrumbdata: [
        {
          name: "开放平台"
        },
        {
          name: "总览",
          path: "/overview",
          isactive: true
        }
      ],
      listType: 3, // 时间段 1近24⼩小时，2近7天，3近30天
      listTypeOptions: [
        {
          value: '1',
          lebel: '昨日',
        },
        {
          value: '2',
          lebel: '近7天',
        },
        {
          value: '3',
          lebel: '近30天',
        },
      ],
      draw_cicle:'',
      draw_line:'',
    }
  },
  created() {},
   watch: {
        screenWidth(val) {
            this.screenWidth = val
            this.draw_cicle.resize()
            this.draw_line.resize()
        }
      },
  mounted() {
     const self = this
    // 基于准备好的dom，初始化echarts实例
     window.onresize = () => {
        return (() => {
            window.screenWidth = document.body.clientWidth
            self.screenWidth = window.screenWidth
        })()
     }
    this.drawServiceChart()
    this.drawCategoryChart()
     this.idCardCheck().then(()=>{
        if(this.exist == 1){
            // this.init()
            // 企业认证信息查询
            this.getApply().then(()=>{
              if(this.apply.state === 2){ //已经实名
                  // 开放平台当前⽤户使⽤用次数及详情
                  this.getStatisticTotal()
                  // 一段时间当前用户服务使用情况 
                  this.getStatisticTimeList()
                  // 当前登录用户余额信息查询
                  this.getWalletBalance()
                  // 当前登录用户本⽉消费查询 
                  this.getWalletSpend()
                  //存证套餐余量
                  this.getCurrentPackageTotal()
                  // 用户取证套餐余量
                  // this.getEvobtainUsable()
              }else if(this.apply.state === 1){ // //实名审核中
                 // alert('实名审核中')
              }else if (this.apply.state === 3) { //实名审核未通过
                // alert('实名审核未通过')
              }else{ // 未实名
               //  alert('未实名')
              }
            })
        }
     })

  },
  methods: {
    // 检验企业申请人实名信息是否通过
    idCardCheck() {
        this.p1 =new Promise((resolve,rej)=>{
            checkIdCard().then((res) => {
              this.$message.closeAll()
              if (res.retCode === 0) {
                console.log("this.exist",this.exist)
                this.exist = res.exist
                resolve(res.exist)
              } else {
                this.$message.error(res.retMsg)
                rej()
              }
            })
         })
         return this.p1
    },
    // 去套餐购买页面
    goWallet(str){
      window.open(window.toolbox_redirectUrl.wallet + '?' + str + "=true")
    },
    // getEvobtainUsable(){
    //    getEvobtainUsable().then((res) => {
    //         if (res.retCode == 0) {
    //           if(res.detail){
    //               const filterData = res.detail.filter((item)=>{
    //                 return item.busiDetailType == 1
    //               })
    //               this.getusable = filterData[0].usable
    //           }
    //         }else{
    //           this.$message.error(res.retMsg)
    //         }
    //     })
    // },

    // 存证api 套餐余量
    getCurrentPackageTotal(){
       getCurrentPackageTotal().then((res) => {
            if (res.retCode === 0) {
               if(!res.detail){
                return
              }
               // 过滤出存证的状态 2
             const filterdata = res.detail.filter((item)=>{
               return item.busiDetailType = 2 
             })
             if(filterdata.length){
                this.usable = filterdata[0].usable
             }
              // this.usable = res.detail.usable
            }else{
              this.$message.error(res.retMsg)
            }
        })
    },
    getWalletSpend(){
        getWalletSpend().then((res) => {
            if (res.retCode === 0) {
              if(res.detail){
                 // 1取证 2 开放平台 HASH存证
                 const filterData = res.detail.filter((item)=>{
                    return item.busiDetailType == 2
                  })
                 this.mounthConsumption = filterData[0].totalPrice
              }
              // this.mounthConsumption = this.numberFormat(res.detail.spend).value + this.numberFormat(res.detail.spend).unit
            }else{
              this.$message.error(res.retMsg)
            }
        })
    },

     numberFormat(value) {
      let param = {}
      let k = 10000
      let sizes = ['', '万', '亿', '万亿']
      let i = 0
      if (value < k) {
        param.value = value
        param.unit = ''
      } else {
        i = Math.floor(Math.log(value) / Math.log(k))
        param.value = (value / Math.pow(k, i)).toFixed(2)
        param.unit = sizes[i]
      }
      return param
    },

    getWalletBalance(){
        getWalletBalance().then((res) => {
            if (res.retCode === 0) {
               this.accountBalance = res.detail.balance
              // this.accountBalance = this.numberFormat(res.detail.balance).value + this.numberFormat(res.detail.balance).unit
            }else{
              this.$message.error(res.retMsg)
            }
        })
    },
    changeBusinessTrendsActive(val,str){
        return
        console.log("val12",val)
         this[str] = val
         this.timeBusinessTrendsActive = 1
         this.getStatisticTimeList(val)
    },
    changeTimeBusinessTrendsActive(val, str) {
      this[str] = val
      console.log("val",val)
      this.getStatisticTimeList(val)
    },
    getStatisticTimeList(listType){
     const params = {
        busiDetailType: this.businessTrendsActive, // 业务类型
        listType: this.timeBusinessTrendsActive, // 1近24⼩小时，2近7天，3近30天
      }
       getStatisticTimeList(params).then((res) => {
           if (res.retCode === 0) {
             // 获得折线图
            this.brokenStatisticTime = []
            this.brokenStatisticNum = []
            if (res.detail) {
                res.detail.forEach((item) => {
                  if(item.statisticTime.length == 2){
                    item.statisticTime = item.statisticTime + ':00'
                  }
                  this.brokenStatisticTime.push(item.statisticTime)
                  this.brokenStatisticNum.push(item.statisticNum)
                })
                this.drawServiceChart()
            }
           }else{
             this.$message.error(res.retMsg)
           }
        })
    },
    getStatisticTotal(){
         getStatisticTotal().then((res) => {
           if (res.retCode === 0) {
             this.useTotal = res.total
             if(res.detail && res.detail.length){
               // 提取数据
              this.serviceCategory=[]
              this.serviceCategoryData=[]
              res.detail.forEach((item,index)=>{
                let _name = getBusiDetailType[item.busiDetailType]
                this.serviceCategory.push(_name)
                this.serviceCategoryData.push({
                  value:item.total,
                  name:_name
                })
              })
              this.drawCategoryChart()
          }
           }else{
             this.$message.error(res.retMsg)
           }
        })
    },

    getApply(){
       return getApply().then((res) => {
           if (res.retCode === 0) {
             console.log("res",res.detail)
              if (res.detail && res.detail.state) {
                this.apply = res.detail
                this.epName = res.detail.epName
                this.email = res.detail.email
              }
           }
        })
    },
    init() {
      const self = this
      window.onresize = function() {
        if (self.$refs && self.$refs.categoryChart) {
          self.chart01 = self.$echarts.init(self.$refs.categoryChart)
          self.chart01.resize()
        }
        if (self.$refs && self.$refs.serviceChart) {
          self.chart02 = self.$echarts.init(self.$refs.serviceChart)
           if (self.brokenStatisticTime.length > 0) {
                 self.chart02.resize()
           }
        }
      }
    },
    drawCategoryChart() {
      const colorList = ["#5ca1f8", "#79c87e", "#f5d464", "#de5667", "#8f68de"]
      const categoryoption = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c}次 ({d}%)"
        },
        title: {
          show: this.serviceCategoryData.length === 0,
          extStyle: {
            color: 'grey',
            fontSize: 20,
          },
          text: '暂无数据',
          left: 'center',
          top: 'center',
        },
        legend: {
          orient: "vertical",
          right: "5%",
          formatter: function(name) {
            var total = 0
            var target
            for (
              var i = 0, l = categoryoption.series[0].data.length;
              i < l;
              i++
            ) {
              total += categoryoption.series[0].data[i].value;
              if (categoryoption.series[0].data[i].name === name) {
                target = categoryoption.series[0].data[i].value;
              }
            }
            var arr = [
              name +
                " " +
                ((target / total) * 100).toFixed(2) +
                "%" +
                " " +
                target +
                "次"
            ];
            return arr.join("\n")
          },
          top: "center",
          data: this.serviceCategory
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["50%", "70%"],
            center: ["25%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              normal: {
                color: function(params) {
                  return colorList[params.dataIndex];
                }
              }
            },
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "20",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data:this.serviceCategoryData
          }
        ]
      }
      // 基于准备好的dom，初始化echarts实例
     this.draw_cicle = this.$echarts.init(
        document.getElementById("categoryChart")
      )
      // 绘制图表
      this.draw_cicle.setOption(categoryoption)
    },
    drawServiceChart() {
      const serviceoption = {
        title: {
          show: this.brokenStatisticTime.length === 0,
          extStyle: {
            color: 'grey',
            fontSize: 20,
          },
          text: '暂无数据',
          left: 'center',
          top: 'center',
        },
         tooltip: {
          trigger: 'axis',
          formatter(params) {
            return `${params[0].name}<br><span style="background:#5ca1f8;width:10px;height:10px;border-radius:50%;display:inline-block;margin-right:5px"></span>${params[0].data}`
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.brokenStatisticTime,
        },
        yAxis: {
          type: "value"
        },
        grid: {
          top: '10px',
          left: '20',
          right: '38',
          bottom: '5%',
          containLabel: true,
        },
        series: [
          {
            data: this.brokenStatisticNum,
            type: "line",
            smooth: true,
            areaStyle: {
              color: "#f3f6ff"
            },
            lineStyle: {
              color: "rgba(107, 144, 243, 0.9)"
            }
          }
        ]
      }
      // 基于准备好的dom，初始化echarts实例
      this.draw_line = this.$echarts.init(
        document.getElementById("serviceChart")
      )
      // 绘制图表
      this.draw_line.setOption(serviceoption)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-divider--vertical{
  margin: 0 10px;
}
.none {
  display: none !important;
}
.content-wrap {
  padding: 30px;
  .divider {
    margin: 30px 0;
  }
}
.user-info {
  display: flex;
  justify-content: space-between;

  .user-info-left {
    display: flex;
    .img {
      width: 60px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      border-radius: 50%;
      background-color: #edeef0;
      margin-right: 20px;
      .icon {
        font-size: 33px;
        color: #4d659e;
      }
    }
    .txt1 {
      font-size: 16px;
      line-height: 20px;
      color: #333333;
    }
    .txt2 {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0px;
      color: #999999;
      margin-top: 10px;
    }
  }
  .user-info-right {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #666666;
    margin-right: 45px;
    .txt1 {
      font-size: 36px;
      line-height: 20px;
      letter-spacing: 0px;
      color: #e3b042;
    }
  }
}
.use-money {
  display: flex;
  justify-content: space-between;
  .use-money-left {
     width: 58%;
     flex: 1;
    .mb20 {
      margin-bottom: 20px;
    }
    .list {
      display: flex;
      .mr20 {
        margin-right: 20px;
      }
      .list-card {
        flex: 1;
        // width: 230px;
        padding: 0 10px;
        height: 104px;
        background-blend-mode: normal, normal;
        border-radius: 6px;
        border: solid 1px #d6e9ff;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: #fff;
        .img {
          width: 56px;
          height: 56px;
          border: 2px solid #fff;
          border-radius: 50%;
          line-height: 56px;
          text-align: center;
          margin: 10px;
        }
        .icon {
          font-size: 32px;
        }
        .txt1 {
          font-size: 14px;
          letter-spacing: 0px;
        }
        .txt2 {
          font-size: 26px;
          font-weight: bold;
          letter-spacing: 0px;
        }
        .txt3 {
          width:30px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          margin-top: -38px;
          cursor: pointer;
        }
         .txt3:hover{
            text-decoration: underline;
         }
        .con{
          flex: 1
        }
      }

      .list-card1 {
        background-image: linear-gradient(90deg, #37508e 0%, #5871ad 100%),
          linear-gradient(#fdfeff, #fdfeff);
        box-shadow: 0px 3px 15px 1px rgba(32, 55, 112, 0.27);
      }
      .list-card2 {
        background-image: linear-gradient(90deg, #347ed7 0%, #559df2 100%),
          linear-gradient(#fdfeff, #fdfeff);
        box-shadow: 0px 3px 15px 1px rgba(17, 146, 192, 0.3);
      }
      .list-card3 {
        margin-right: 20px;
        flex-grow: 0.5;
        background-color: #b390e8;
        box-shadow: 0px 3px 15px 1px rgba(148, 102, 201, 0.27);
      }
      .list-card4 {
        background-image: linear-gradient(90deg, #e5af41 0%, #f2c261 100%),
          linear-gradient(#fdfeff, #fdfeff);
        box-shadow: 0px 3px 15px 1px rgba(195, 144, 39, 0.27);
      }
      .list-card-gap{
        width: 20px;
      }
    }
  }
  .use-money-right {
    // flex: 1;
     width: 42%;
    margin-left: 20px;
    .txt1 {
      font-size: 18px;
      letter-spacing: 0px;
      color: #333333;
      border-left: 4px solid #2455ce;
      padding-left: 10px;
    }
  }
}
.use-service {
  .tt1 {
    font-size: 18px;
    letter-spacing: 0px;
    color: #333333;
    border-left: 4px solid #2455ce;
    padding-left: 10px;
    margin-bottom: 15px;
  }
  .left {
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    display: flex;
    align-items: center;
    .tt2 {
      margin-right: 8px;
    }
    .tabs {
      display: flex;
      // width: 180px;
      width: 90px;
      height: 30px;
      // line-height: 30px;
      background-color: #ffffff;
      border-radius: 4px;
      border: solid 1px #dbe0ef;
      .tt3 {
        flex: 1;
        text-align: center;
        line-height: 30px;
        // cursor: pointer;
      }
      .active {
        background-color: #385abd;
        font-size: 14px;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }
  }
  .right {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #666;
    .item{
      cursor: pointer;
    }
    .active {
      color: #385abd;
    }
  }
  .use-service-category {
    display: flex;
    justify-content: space-between;
  }
}
.money-num{
    max-width: 100%;
    margin-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    &:hover {
      cursor: pointer;
    }
}
@media screen and (min-width: 1280px) and (max-width: 1536px) {
  .money-num {
    width: 148px !important;
    font-size: 20px!important;
  }
}
@media screen and (max-width: 1280px) {
  .money-num {
    width: 106px !important;
    font-size: 16px !important;
  }
}
</style>
